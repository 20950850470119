import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { commonIcon } from "../../asset";
import { continueWithGoogleOauth } from "../../utils/getGoogleOauthUrl";
import { useDispatch, useSelector } from "react-redux";
import { loginEmailHandler } from "../../store/auth-action";
import ForgotPopup from "./ForgotPopup";

export default function LoginForm() {
  const isLogin = useSelector((state) => state.auth.isLogin);

  const loginHandler = () => {
    window.open(continueWithGoogleOauth(), "_self");
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userInput, setUserInput] = useState({
    email: "",
    password: "",
  });
  const [toggleForgot, setToggleForgot] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInput({
      ...userInput,
      [name]: value,
    });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    dispatch(loginEmailHandler(userInput));
  };

  useEffect(() => {
    if (isLogin) {
      navigate("/home");
    }
  }, [isLogin, navigate]);

  return (
    <div className="sm:bg-gray-100 flex items-center justify-center gap-24 min-h-screen bg-white">
      <div className="w-full max-w-96 p-8 space-y-6 bg-white sm:rounded-lg sm:shadow-lg sm:shadow-[#0000006e]">
        <div className="flex flex-col justify-center items-center">
          <img
            src={commonIcon.fullLogo}
            className="max-w-80"
            alt="Gospel Truth AI"
          />
          <h2 className="text-2xl font-semibold text-center text-[#0173B8] uppercase">
            Login
          </h2>
        </div>
        {/* <h2 className="text-2xl font-thin text-center">Login</h2> */}
        <form className="space-y-4" onSubmit={onSubmitHandler}>
          <div className="relative">
            <input
              autoFocus={true}
              id="email"
              type="email"
              name="email"
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border border-gray-200 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-600 focus:outline-none focus:ring-1 focus:border-blue-600 peer"
              placeholder=""
              onChange={handleChange}
              required
            />
            <label
              htmlFor="email"
              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-2"
            >
              Email
            </label>
          </div>
          <div className="relative">
            <input
              id="password"
              type="password"
              name="password"
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border border-gray-200 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-600 focus:outline-none focus:ring-1 focus:border-blue-600 peer"
              placeholder=""
              onChange={handleChange}
              required
              minLength={6}
            />
            <label
              htmlFor="password"
              className="absolute text-sm text-gray-500 dark:text-gray-400 bg-white duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-2"
            >
              Password
            </label>
          </div>
          <button
            type="submit"
            className="w-full px-4 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50"
          >
            Continue
          </button>
        </form>
        <p className="text-center text-sm text-gray-600">
          Don’t have an account?{" "}
          <Link to="/signup" className="text-blue-600 hover:text-blue-800">
            Sign up
          </Link>
        </p>
        <div className="flex items-center justify-center space-x-2">
          <span className="text-sm text-gray-600">or</span>
          <button
            onClick={loginHandler}
            className="flex items-center px-4 py-2 border rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50"
          >
            <img
              src="https://www.google.com/images/branding/googleg/1x/googleg_standard_color_128dp.png"
              alt="Google Logo"
              className="h-5 w-5"
            />
            <span className="ml-2 text-gray-600">Continue with Google</span>
          </button>
        </div>
        <p className="text-center text-sm text-gray-600">
          <Link
            to="#"
            onClick={() => setToggleForgot(true)}
            className="text-blue-600 hover:text-blue-800"
          >
            Forgot your password?
          </Link>
        </p>
      </div>
      {toggleForgot && (
        <ForgotPopup togglePopup={() => setToggleForgot(false)} />
      )}
    </div>
  );
}
