import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { resetPassword } from "../../store/auth-action";
import { toast } from "react-toastify";
import LoadingOverlay from "../../components/Ui/LoadingOverlay";

export default function LoginForm() {
  const isLogin = useSelector((state) => state.auth.isLogin);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");
  const [userInput, setUserInput] = useState({
    code: null,
    email: email,
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInput({
      ...userInput,
      [name]: value,
    });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (userInput.password?.trim() !== userInput.cfpassword?.trim()) {
      toast.error("Password does not match");
      return;
    }
    const callAPI = async () => {
      setLoading(true);
      try {
        await resetPassword(userInput);
        setSent(true);
        setTimeout(() => {
          setLoading(false);
          toast.success("Reset Successfully");
        }, 1000);
      } catch (error) {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      }
    };
    callAPI();
  };

  useEffect(() => {
    if (isLogin) {
      navigate("/home");
    }
  }, [isLogin, navigate]);

  return (
    <div className="sm:bg-gray-100 flex items-center justify-center gap-24 min-h-screen bg-white">
      <div className="w-full max-w-96 p-8 space-y-6 bg-white sm:rounded-lg sm:shadow-lg sm:shadow-[#0000006e]">
        <div className="flex flex-col justify-center items-center">
          {/* <img
            src={commonIcon.fullLogo}
            className="max-w-80"
            alt="Gospel Truth AI"
          /> */}
          <h2 className="text-2xl font-semibold text-center text-[#0173B8] uppercase">
            Reset Password
          </h2>
          <p className="mb-4 text-blue-600">
            Please check your email to get verify code.
          </p>
        </div>
        {/* <h2 className="text-2xl font-thin text-center">Login</h2> */}
        <form className="space-y-4" onSubmit={onSubmitHandler}>
          <div className="relative">
            <input
              autoFocus={true}
              id="email"
              type="email"
              name="email"
              className="block cursor-not-allowed px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border border-gray-200 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-600 focus:outline-none focus:ring-1 focus:border-blue-600 peer"
              placeholder=""
              disabled
              defaultValue={userInput?.email}
              required
            />
            <label
              htmlFor="email"
              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-2"
            >
              Email
            </label>
          </div>
          <div className="relative">
            <label
              htmlFor="code"
              className="absolute text-sm text-gray-500 dark:text-gray-400 !bg-white duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-2"
            >
              Verify Code
            </label>
            <input
              type="number"
              name="code"
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 !bg-transparent rounded-md border border-gray-200 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-600 focus:outline-none focus:ring-1 focus:border-blue-600 peer"
              placeholder=""
              disabled={sent}
              onChange={handleChange}
              required
            />
          </div>
          <div className="relative">
            <label
              htmlFor="password"
              className="absolute text-sm text-gray-500 dark:text-gray-400 bg-white duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-2"
            >
              Password
            </label>
            <input
              type="password"
              name="password"
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border border-gray-200 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-600 focus:outline-none focus:ring-1 focus:border-blue-600 peer"
              placeholder=""
              disabled={sent}
              onChange={handleChange}
              required
              minLength={6}
            />
          </div>
          <div className="relative">
            <label
              htmlFor="cfpassword"
              className="absolute text-sm text-gray-500 dark:text-gray-400 bg-white duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-2"
            >
              Confirm Password
            </label>
            <input
              type="password"
              name="cfpassword"
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border border-gray-200 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-600 focus:outline-none focus:ring-1 focus:border-blue-600 peer"
              placeholder=""
              disabled={sent}
              onChange={handleChange}
              required
              minLength={6}
            />
          </div>
          <button
            type="submit"
            disabled={sent}
            className="w-full px-4 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50"
          >
            Submit
          </button>
        </form>
        <p className="text-center text-sm text-gray-600">
          <Link to="/login" className="text-blue-600 hover:text-blue-800">
            Back to login now
          </Link>
        </p>
      </div>
      {loading && <LoadingOverlay />}
    </div>
  );
}
