import styles from "./Header.module.css";
import { useDispatch, useSelector } from "react-redux";
import { uiAction } from "../../../store/ui-gemini";
import { themeIcon } from "../../../asset";
import { commonIcon } from "../../../asset";
import { useNavigate } from "react-router-dom";
import { chatAction } from "../../../store/chat";

const AdminHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isNewChat = useSelector((state) => state.chat.newChat);
  const isUserDetails = useSelector((state) => state.ui.isUserDetailsShow);
  const isLogin = useSelector((state) => state.auth.isLogin);
  const userDetails = useSelector((state) => state.user.user);

  const toggleSideBarHandler = () => {
    dispatch(uiAction.toggleAdminSidebar());
  };

  const icon = themeIcon();

  const newChatHandler = () => {
    dispatch(chatAction.replaceChat({ chats: [] }));
    dispatch(chatAction.newChatHandler());
    dispatch(chatAction.chatHistoryIdHandler({ chatHistoryId: "" }));
    navigate("/home");
  };

  const userDetailsOpen = () => {
    dispatch(uiAction.toggleUserDetailsShow());
  };

  return (
    <div className={`${styles["header-main"]} border-b shadow-sm`}>
      <div className={styles["left-section"]}>
        <div className={styles["menu-icon"]} onClick={toggleSideBarHandler}>
          <img src={icon.menuIcon} className="!size-9" alt="menu icon"></img>
        </div>
        <div className={styles["name"]} onClick={() => navigate("/home")}>
          <img
            src={commonIcon.fullLogo2}
            className="!w-40 !h-auto"
            alt="Gospel Truth AI"
            title="Home"
          ></img>
        </div>
      </div>
      <div className={styles["right-section"]}>
        {isNewChat ? (
          <div
            onClick={newChatHandler}
            className={`${styles["plus-icon"]} ${styles["new-plus-icon"]}`}
          >
            <img src={icon.plusIcon} alt="plus icon"></img>
          </div>
        ) : null}
        {isLogin && (
          <div
            onClick={userDetailsOpen}
            className={`${styles["user"]} ${
              isUserDetails ? styles["clicked-user"] : ""
            }`}
          >
            {userDetails?.profileImg?.length > 0 ? (
              <img src={userDetails?.profileImg} alt=""></img>
            ) : (
              <img src={commonIcon.defaultAvatar} alt=""></img>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminHeader;
