import React, { useState } from "react";

import { commonIcon } from "../../../asset";

const Dropdown = ({
  allowEdit = false,
  allowDelete = false,
  allowView = false,
  id,
  onDelete,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleView = () => {
    console.log("View clicked");
  };

  const handleEdit = () => {
    console.log("Edit clicked");
  };

  const handleDelete = () => {
    onDelete();
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative inline-block text-left">
      <div>
        <div
          onClick={handleToggle}
          className="inline-flex justify-center w-full cursor-pointer rounded-lg hover:shadow-md hover:scale-105 hover:bg-slate-100 p-2"
        >
          <img
            className="size-5 rotate-90"
            src={commonIcon.threedottIcon}
            alt="Action"
          ></img>
        </div>
      </div>

      {isOpen && (
        <div
          className="origin-top-right z-20 absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
          role="menu"
        >
          <div className="py-1" role="none">
            {allowView && (
              <button
                onClick={handleView}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                role="menuitem"
              >
                View
              </button>
            )}
            {allowEdit && (
              <button
                onClick={handleEdit}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                role="menuitem"
              >
                Edit
              </button>
            )}
            {allowDelete && (
              <button
                onClick={handleDelete}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                role="menuitem"
              >
                Delete
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
