import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { adminChangePassword } from "../../../store/user-action";

const AdminChangePassword = () => {
  const userDetails = useSelector((state) => state.user.user);
  const [loading, setLoading] = useState(false);

  const [userInput, setUserInput] = useState({
    email: userDetails.email,
    currentPassword: "",
    password: "",
    cfpassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInput({
      ...userInput,
      [name]: value,
    });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (userInput.password?.trim() !== userInput.cfpassword?.trim()) {
      toast.error("Password does not match");
      return;
    }
    const callAPI = async () => {
      setLoading(true);
      try {
        await adminChangePassword(
          userInput.password,
          userInput.currentPassword
        );
        setTimeout(() => {
          setLoading(false);
        }, 500);

        toast.success("Successfully");
      } catch (error) {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      }
    };
    callAPI();
  };

  return (
    <form
      onSubmit={onSubmitHandler}
      className="p-4 col-span-6 md:col-span-4  [&_input]:outline-none"
    >
      <div className="mx-auto grid grid-cols-2 gap-x-8 gap-y-10">
        <div className="col-span-2">
          <label
            htmlFor="email"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Email address
          </label>
          <div className="mt-2">
            <input
              id="email"
              name="email"
              type="email"
              disabled
              value={userInput?.email}
              onChange={handleChange}
              className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="col-span-2">
          <label
            htmlFor="currentPassword"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Current Password
          </label>
          <div className="mt-2">
            <input
              disabled={loading}
              type="password"
              name="currentPassword"
              id="currentPassword"
              value={userInput?.currentPassword}
              onChange={handleChange}
              minLength={6}
              required
              className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="col-span-2">
          <label
            htmlFor="password"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            New Password
          </label>
          <div className="mt-2">
            <input
              disabled={loading}
              type="password"
              name="password"
              id="password"
              value={userInput?.password}
              onChange={handleChange}
              minLength={6}
              required
              className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div className="col-span-2">
          <label
            htmlFor="cfpassword"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Confirm Password
          </label>
          <div className="mt-2">
            <input
              disabled={loading}
              type="password"
              name="cfpassword"
              id="cfpassword"
              value={userInput?.cfpassword}
              onChange={handleChange}
              minLength={6}
              required
              className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
      </div>

      <div className="mt-6 flex items-center justify-start gap-x-6">
        <button
          disabled={loading}
          type="submit"
          className="disabled:opacity-25 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default AdminChangePassword;
