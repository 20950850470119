/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import { useState } from "react";
import { Link } from "react-router-dom";
import { commonIcon } from "../../asset";
import { continueWithGoogleOauth } from "../../utils/getGoogleOauthUrl";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch } from "react-redux";
import { signupHandler } from "../../store/auth-action";
import { toast } from "react-toastify";

export default function SignupForm() {
  const dispatch = useDispatch();
  const [checkTerm, setCheckTerm] = useState(false);
  const loginHandler = () => {
    window.open(continueWithGoogleOauth(), "_self");
  };

  const [userInput, setUserInput] = useState({
    email: "",
    phone: "",
    password: "",
    cfpassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUserInput({
      ...userInput,
      [name]: value,
    });
  };
  const handleOnChange = (value, data, event, formattedValue) => {
    setUserInput((prevState) => ({
      ...prevState,
      phone: value,
    }));
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (!checkTerm) {
      toast.info("Please agree to the terms and conditions before submitting");
      return;
    }
    if (userInput.password?.trim() != userInput.cfpassword?.trim()) {
      toast.error("Password does not match");
      return;
    }

    dispatch(signupHandler(userInput));
  };

  return (
    <div className="sm:bg-gray-100 flex items-center justify-center min-h-screen gap-24">
      <div className="w-full max-w-96 p-8 space-y-6 bg-white sm:rounded-lg sm:shadow-2xl sm:shadow-[#0000006e]">
        <div className="flex flex-col justify-center items-center">
          <img
            src={commonIcon.fullLogo}
            className="max-w-80"
            alt="Gospel Truth AI"
          />
          <h2 className="text-2xl font-semibold text-center text-[#0173B8] uppercase">
            Sign Up
          </h2>
        </div>
        {/* <h2 className="text-2xl font-thin text-center">Sign Up</h2> */}
        <form
          className="space-y-4"
          onSubmit={onSubmitHandler}
          autoComplete="off"
        >
          <div className="relative">
            <input
              autoFocus={true}
              type="text"
              id="fullname"
              name="fullname"
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border border-gray-200 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-600 focus:outline-none focus:ring-1 focus:border-blue-600 peer"
              placeholder=""
              onChange={handleChange}
              required
            />
            <label
              htmlFor="fullname"
              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-2"
            >
              Fullname
            </label>
          </div>
          <div className="relative">
            <input
              type="email"
              id="email"
              name="email"
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border border-gray-200 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-600 focus:outline-none focus:ring-1 focus:border-blue-600 peer"
              placeholder=""
              onChange={handleChange}
              required
            />
            <label
              htmlFor="email"
              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-2"
            >
              Email
            </label>
          </div>
          <div className="relative">
            <PhoneInput
              inputProps={{
                name: "phone",
                required: true,
                autoFocus: false,
              }}
              containerClass="w-full"
              buttonClass="border !border-gray-200 !rounded-l-md !z-50"
              inputClass="w-full !h-[48px] !rounded-md border !border-gray-200 focus:outline-none focus:ring-1 !focus:border-blue-900 peer"
              country={"us"}
              onChange={handleOnChange}
            />
          </div>
          <div className="relative">
            <input
              type="password"
              id="password"
              name="password"
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border border-gray-200 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-600 focus:outline-none focus:ring-1 focus:border-blue-600 peer"
              placeholder=""
              onChange={handleChange}
              minLength={6}
              required
            />
            <label
              htmlFor="password"
              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-2"
            >
              Password
            </label>
          </div>
          <div className="relative">
            <input
              type="password"
              id="cfpassword"
              name="cfpassword"
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border border-gray-200 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-600 focus:outline-none focus:ring-1 focus:border-blue-600 peer"
              placeholder=""
              required
              onChange={handleChange}
              minLength={6}
            />
            <label
              htmlFor="cfpassword"
              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-2"
            >
              Password
            </label>
          </div>
          {/* <div className="flex items-center">
            <input
              type="checkbox"
              id="verify"
              name="verify"
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <label htmlFor="verify" className="ml-2 block text-sm text-gray-900">
              Verify you are human
            </label>
          </div> */}
          <div className="flex flex-row gap-5 items-center">
            <input
              type="checkbox"
              id="verify"
              name="verify"
              checked={checkTerm}
              onChange={(e) => setCheckTerm((prev) => !prev)}
              className="cursor-pointer h-6 w-6 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <p className="text-sm text-gray-600 block">
              By clicking Continue, you agree to the{" "}
              <a
                href="/term/terms-of-service"
                target="_blank"
                className="text-blue-600 hover:text-blue-800"
              >
                Terms of Service
              </a>{" "}
              and the{" "}
              <a
                href="/term/privacy-policy"
                target="_blank"
                className="text-blue-600 hover:text-blue-800"
              >
                Privacy Notice
              </a>
            </p>
          </div>
          <button
            type="submit"
            className="w-full px-4 py-2  text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50"
          >
            Continue
          </button>
        </form>
        <p className="text-center text-sm text-gray-600">
          Already have an account?{" "}
          <Link to="/login" className="text-blue-600 hover:text-blue-800">
            Log in
          </Link>
        </p>
        <div className="flex items-center justify-center space-x-2">
          <span className="text-sm text-gray-600">or</span>
          <button
            onClick={loginHandler}
            className="flex items-center px-4 py-2 border rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50"
          >
            <img
              src="https://www.google.com/images/branding/googleg/1x/googleg_standard_color_128dp.png"
              alt="Google Logo"
              className="h-5 w-5"
            />
            <span className="ml-2 text-gray-600">Sign up with Google</span>
          </button>
        </div>
      </div>
      <div className="w-full max-w-md p-10 hidden lg:block space-y-6 text-gray-700 [&_h4]:text-center">
        <div className="flex items-start flex-col mb-5">
          <img
            src={commonIcon.fullLogo}
            loading="lazy"
            alt="By signing up today, you will get access to all free account of
            Gospel Truth AI Access."
          />
          <h4>
            By signing up today, you will get access to all free account of
            Gospel Truth AI Access.
          </h4>
        </div>
        <h4>Get Truely Authicate response from strong library source.</h4>
        <h4>No Credit card is required. It's Unique & Best.</h4>
      </div>
    </div>
  );
}
