import styles from "./Sidebar.module.css";
import { themeIcon } from "../../../asset";
import { useSelector, useDispatch } from "react-redux";
import { uiAction } from "../../../store/ui-gemini";
import { Link } from "react-router-dom";

const AdminSidebar = () => {
  const dispatch = useDispatch();
  const isAdminSidebarLong = useSelector(
    (state) => state.ui.isAdminSidebarLong
  );

  const sideBarWidthHandler = () => {
    dispatch(uiAction.toggleAdminSidebar());
  };

  const icon = themeIcon();
  const sideBarWidthClass = isAdminSidebarLong
    ? "side-bar-long"
    : "side-bar-sort";

  return (
    <div
      className={`${styles["admin-sidebar-main"]} ${styles[sideBarWidthClass]}`}
    >
      <div className={styles["menu-icon"]} onClick={sideBarWidthHandler}>
        <img src={icon.menuIcon} className="!size-9" alt="menu icon"></img>
      </div>

      <div className={styles["settings-section"]}>
        <div className={styles["oveview"]}>
          <Link
            to="user"
            className="border-b w-full p-2 rounded-md hover:bg-slate-300 mr-2"
          >
            {/* <img className="icon" src={icon.helpIcon} alt="help icon"></img> */}
            {isAdminSidebarLong && <p>Users</p>}
          </Link>
        </div>{" "}
        <div className={styles["oveview"]}>
          <Link
            to="plan"
            className="border-b w-full p-2 rounded-md hover:bg-slate-300 mr-2"
          >
            {isAdminSidebarLong && <p>Plans</p>}
          </Link>
        </div>
        <div className={styles["user"]}>
          <Link
            to="subscription"
            className="border-b w-full p-2 rounded-md hover:bg-slate-300 mr-2"
          >
            {/* <img className="icon" src={icon.helpIcon} alt="help icon"></img> */}
            {isAdminSidebarLong && <p>Subscriptions</p>}
          </Link>
        </div>
        <div className={styles["user"]}>
          <Link
            to="transaction"
            className="border-b w-full p-2 rounded-md hover:bg-slate-300 mr-2"
          >
            {/* <img className="icon" src={icon.helpIcon} alt="help icon"></img> */}
            {isAdminSidebarLong && <p>Transaction</p>}
          </Link>
        </div>
        <div className={styles["user"]}>
          <Link
            to="setting"
            className="border-b w-full p-2 rounded-md hover:bg-slate-300 mr-2"
          >
            {/* <img className="icon" src={icon.helpIcon} alt="help icon"></img> */}
            {isAdminSidebarLong && <p>Settings</p>}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AdminSidebar;
