import axios from "axios";

export const adminGetConfig = async (id, status) => {
  const url = `/api/admin/config`;
  return axios(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const adminUpdateConfig = async (data) => {
  const url = `/api/admin/config`;
  return axios(url, {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  });
};
