/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { redirectHandler } from "../../store/auth-action";
import { useDispatch } from "react-redux";

import { useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

const GoogleAuthHandleRedirect = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const code = searchParams.get("code");

  useEffect(() => {
    if (!code) return;

    const handleRedirect = async () => {
      try {
        const rs = await redirectHandler(code);
        navigate("/home");
      } catch (error) {
        console.log("ssss", error);
      }
    };
    handleRedirect();
  }, [code]);

  return <></>;
};

export default GoogleAuthHandleRedirect;
