/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import LoadingOverlay from "../../components/Ui/LoadingOverlay";
import { resendVerifyEmail } from "../../store/auth-action";

import { useLocation } from "react-router-dom";

const EmailVerification = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isCount, setIsCount] = useState(1);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const email = searchParams.get("email");

  useEffect(() => {
    if (isCount > 0) {
      const timer = setInterval(() => {
        setIsCount((prevCount) => prevCount - 1);
      }, 1000);

      // Clean up the interval on component unmount
      return () => clearInterval(timer);
    }
  }, [isCount]);

  const handleResendEmail = async (e) => {
    setIsCount(60);

    try {
      setIsLoading(true);
      await resendVerifyEmail(email);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
          <div className="p-8 max-w-xl mx-auto">
            <h1 className="text-lg font-bold mb-2">
              Hi <span className="text-blue-600">{email}</span>
            </h1>
            <p className="text-gray-700 mb-2">
              We just sent an email to{" "}
              <span className="font-bold">
                {email ? email : "your email address"}
              </span>
              . Please verify your email to continue.
            </p>
            {isCount <= 1 ? (
              <>
                <span>
                  Didn't receive it? Please wait for a few minutes and{" "}
                </span>
                <button
                  className="text-blue-600 hover:underline"
                  onClick={(e) => handleResendEmail(e)}
                >
                  try again
                </button>
              </>
            ) : (
              <>
                <span>Resend after </span>{" "}
                <span className="text-red-500 size-5 font-bold">{isCount}</span>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default EmailVerification;
