import { commonIcon } from "../../asset";

const SubscriptionSuccess = () => {
  return (
    <div className="h-screen">
      <div className="flex flex-col mx-auto md:w-4/6 h-4/5 justify-center items-cente px-2">
        {/* <div className="flex items-center p-4 pb-2 justify-between">
          <h2 className="text-[#4CAF50] text-3xl font-bold leading-tight tracking-[-0.015em] flex-1 text-center pl-12 pr-12">
            Payment Successful!
          </h2>
        </div> */}
        <div className="flex w-full justify-center">
          <img
            src={commonIcon.successIcon}
            className="w-44"
            alt="Thank you"
          ></img>
        </div>

        <h1 className="text-[#4CAF50] text-3xl font-bold leading-tight tracking-[-0.015em] px-4 text-center pb-3 pt-5">
          Payment Successful!
        </h1>
        <div className="">
          <div>
            <p className="text-black text-base font-normal leading-normal pb-3 pt-1 px-4">
              Thank you from the heart for your generous payment to Gospel Truth
              AI. We are truly grateful for your support and are honored to have
              you as part of our community. Your transaction was successful, and
              we invite you to enjoy our services at your own pace.
            </p>
            <p className="text-black text-base font-normal leading-normal pb-3 pt-1 px-4">
              Should you need any assistance or simply wish to reach out, our
              support team is always here for you.
            </p>
            <p className="text-black text-base font-normal leading-normal pb-3 pt-1 px-4">
              Wishing you peace and enlightenment as you continue your journey
              with us!
            </p>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="flex flex-1 gap-3 flex-wrap px-4 py-3 max-w-[480px] justify-center">
            <a
              href="/home"
              className="flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-full h-12 px-5 hover:border hover:bg-slate-300 text-gray-700 text-base font-bold leading-normal tracking-[0.015em] grow"
            >
              <span className="truncate">Go to Home</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionSuccess;
