/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import { getSubscriptions } from "../../../store/subscription-action";
import { formatDateToCustomString } from "../../../utils/utils";
import LoadingOverlay from "../../../components/Ui/LoadingOverlay";
import { useLocation, useNavigate } from "react-router-dom";
import { calculatePage } from "../../../utils/paging";
import SearchInput from "../../../components/Ui/Search";
import Pagination from "../../../components/Ui/Paging";

const ManagerSubscription = () => {
  const [subs, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [paging, setPaging] = useState({
    page: parseInt(searchParams.get("page")) || 1,
    limit: parseInt(searchParams.get("limit")) || 10,
    sortBy: searchParams.get("sortBy") || "createdAt",
    sortType: searchParams.get("sortType") || "DESC",
    search: searchParams.get("search") || "",
    total: 0,
    totalPages: 0,
  });

  const setQueryParams = (queryParams) => {
    const searchParams = new URLSearchParams();

    Object.keys(queryParams).forEach((key) => {
      searchParams.set(key, queryParams[key]);
    });

    navigate({ search: searchParams.toString() });
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const dataSubs = await getSubscriptions(paging);
      setTimeout(() => {
        setData(dataSubs.data);
        setPaging((prev) => ({
          ...prev,
          total: dataSubs.total,
          totalPages: calculatePage(dataSubs.total, prev.limit),
        }));
        setLoading(false);
      });
    };
    fetchData();
    setTimeout(() => {
      setQueryParams(paging);
    });
  }, [
    paging.page,
    paging.limit,
    paging.sortBy,
    paging.sortType,
    paging.search,
  ]);

  const handlePageChange = (page) => {
    setPaging((prev) => ({
      ...prev,
      page,
    }));
  };

  const onSearch = (search) => {
    setPaging((prev) => ({
      ...prev,
      search,
    }));
  };

  const displayStatus = (item) => {
    if (new Date(item.nextBillingTime) < new Date()) {
      return (
        <span className="inline-flex items-center bg-red-100 text-red-500 text-xs font-medium px-2.5 py-0.5 rounded-full">
          EXPIRED
        </span>
      );
    } else {
      return (
        <span className="inline-flex items-center bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
          ACTIVE
        </span>
      );
    }
  };

  const dataSection = useMemo(() => {
    return (
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
          <tr>
            <th scope="col" className="px-6 py-3">
              ID
            </th>
            <th scope="col" className="px-6 py-3">
              Email
            </th>
            <th scope="col" className="px-6 py-3">
              Package
            </th>
            <th scope="col" className="px-6 py-3">
              Start
            </th>
            <th scope="col" className="px-6 py-3">
              Next Billing Time
            </th>
            <th scope="col" className="px-6 py-3">
              Status
            </th>
            <th scope="col" className="px-6 py-3">
              <span className="sr-only">Edit</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {!loading ? (
            subs?.map((item, index) => (
              <tr key={index} className="bg-white border-b">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium  whitespace-nowrap"
                >
                  {item.paypalSubscriptionId}
                </th>
                <td className="px-6 py-4">{item.user?.email}</td>

                <td className="px-6 py-4 text-gray-900 font-semibold">
                  {item.plan?.name}
                </td>
                <td className="px-6 py-4">
                  {formatDateToCustomString(item.startTime)}
                </td>
                <td className="px-6 py-4">
                  {formatDateToCustomString(item.nextBillingTime)}
                </td>
                <td className="px-6 py-4">{displayStatus(item)}</td>
              </tr>
            ))
          ) : (
            <LoadingOverlay />
          )}
        </tbody>
      </table>
    );
  }, [subs, loading]);

  const pagine = useMemo(() => {
    if (!paging.totalPages || paging.total <= paging.limit) return <></>;
    return (
      <Pagination
        totalPages={paging.totalPages}
        currentPage={paging.page}
        onPageChange={handlePageChange}
      />
    );
  }, [paging.page, paging.totalPages]);

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <div className="flex w-full justify-between items-center p-3 flex-wrap md:flex-nowrap">
        <h2 className="font-semibold text-3xl text-left rtl:text-right text-gray-900">
          Subscription
        </h2>
        <SearchInput className="md:mr-28" onSubmitSearch={onSearch} />
      </div>
      {dataSection}
      {pagine}
      {loading && <LoadingOverlay />}
    </div>
  );
};

export default ManagerSubscription;
