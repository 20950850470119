import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  adminSentOTPChangeEmail,
  adminVerifyChangeEmail,
} from "../../../store/user-action";

const AdminChangeEmail = () => {
  const userDetails = useSelector((state) => state.user.user);
  const [loading, setLoading] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [otp, setOtp] = useState(null);
  const [isSentOTP, setIsSentOTP] = useState(false);

  const sendEmailVerify = (e) => {
    e.preventDefault();

    const callAPI = async () => {
      setLoading(true);
      try {
        await adminSentOTPChangeEmail(newEmail);
        setTimeout(() => {
          setLoading(false);
          setIsSentOTP(true);
          toast.success("Send Email Successfully");
        }, 500);
      } catch (error) {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      }
    };
    callAPI();
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    const callAPI = async () => {
      setLoading(true);
      try {
        await adminVerifyChangeEmail(otp);
        setTimeout(() => {
          setLoading(false);
        }, 500);

        toast.success("Successfully");
      } catch (error) {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      }
    };
    callAPI();
  };

  return (
    <form
      onSubmit={onSubmitHandler}
      className="p-4 col-span-6 md:col-span-4 [&_input]:outline-none"
    >
      <div className="mx-auto grid grid-cols-2 gap-x-8 gap-y-10">
        <div className="col-span-2">
          <label
            htmlFor="email"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Email address
          </label>
          <div className="mt-2">
            <input
              id="email"
              name="email"
              type="email"
              disabled
              value={userDetails?.email}
              className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="col-span-2">
          <label
            htmlFor="newEmail"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            New email address
          </label>
          <div className="mt-2 flex">
            <input
              id="newEmail"
              disabled={loading}
              name="newEmail"
              type="email"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              className="block w-full rounded-[6px_0px_0px_6px] border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            />
            <button
              disabled={loading || !newEmail}
              onClick={sendEmailVerify}
              type="button"
              className="disabled:opacity-25 rounded-[0px_6px_6px_0px] bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              Send
            </button>
          </div>
        </div>

        <div
          className={`col-span-2 sm:col-span-1 ${
            isSentOTP
              ? "h-auto transition-opacity ease-in-out delay-150 duration-700"
              : "opacity-0 h-0"
          } `}
        >
          <label
            htmlFor="otp"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Enter verification code:
          </label>
          <div className="mt-2">
            <input
              type="number"
              name="otp"
              id="otp"
              disabled={loading}
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
      </div>

      <div className="mt-6 flex items-center justify-start gap-x-6">
        {/* <button
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          Cancel
        </button> */}
        <button
          disabled={loading || !newEmail || !otp}
          type="submit"
          className="disabled:opacity-25 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default AdminChangeEmail;
