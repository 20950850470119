/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import React, { useMemo, useEffect, useState } from "react";
import {
  getPlans,
  saveSubscriptionData,
  approveSubscriptionData,
} from "../../store/subscription-action";
import { getMe } from "../../store/auth-action";
import LoadingOverlay from "../../components/Ui/LoadingOverlay";
import PlanCard from "../../components/Subscription/PlanCard";
import { useSelector, useDispatch } from "react-redux";
import { uiAction } from "../../store/ui-gemini";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Subscription = () => {
  const userDetails = useSelector((state) => state.user.user);
  const isLogin = useSelector((state) => state.auth.isLogin);
  const [isRetrying, setIsRetrying] = useState(false);
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const PLAN_STATUS = {
    INACTIVE: "INACTIVE",
    ACTIVE: "ACTIVE",
  };
  const Free = {
    _id: "free",
    name: "Free",
    status: "ACTIVE",
    description: "",
    usageType: "TRIAL",
    pricingScheme: {
      price: 0,
      currencyCode: "USD",
    },
    sequence: 0,
    totalCycles: 1,
    frequency: {
      intervalUnit: "MONTH",
      intervalCount: 1,
    },
    limitRequest: {
      count: 10,
      unit: "MONTH",
    },
  };
  useEffect(() => {
    const fetchPlans = async () => {
      setLoading(true);
      const data = await getPlans({
        sortBy: "order",
        sortType: "ASC",
        status: PLAN_STATUS.ACTIVE,
      });
      if (userDetails.isSubscribed) {
        setPlans([
          ...data?.data,
          {
            usageType: "BUYMORE",
          },
        ]);
      } else {
        if (!userDetails?.bought) {
          Free.description = `<ul><li>✔️ Limit ${
            userDetails?.config?.freeLimitRate || 10
          } sermons</li><li>✔️ Limited access to advanced data analysis, vision, web browsing</li></ul>`;
          setPlans([Free, ...data?.data]);
        } else {
          setPlans(data?.data);
        }
      }
      setLoading(false);
    };
    fetchPlans();
  }, [userDetails]);

  const currentPlanId = (planId) => {
    if (!userDetails.isSubscribed) {
      return Free._id;
    }
    return userDetails.subscription?.planId;
  };

  const onInit = async (data, actions) => {
    if (!isLogin) {
      actions.disable(); // Disables the PayPal button
    } else {
      actions.enable();
    }
  };

  const onCancel = async (data, actions) => {
    setLoading(false);
  };

  const handleCreate = async (data, actions, planId) => {
    setLoading(true);
    try {
      const subscriptionID = await actions.subscription.create({
        plan_id: planId,
        custom_id: userDetails.id,
        auto_renewal: true,
        application_context: {
          return_url: "http://localhost:3000/return",
          cancel_url: "http://localhost:3000/cancel",
        },
      });

      const subscriptionData = {
        subscriptionId: subscriptionID,
        planId,
      };
      await saveSubscriptionData(subscriptionData);
      return subscriptionID;
    } catch (error) {
      actions?.disable();
      setLoading(false);
      toast.error(
        "Something when wrong, please try again or contact our support."
      );
      return null;
    }
  };
  const handleApprove = async (data, actions) => {
    console.log(data, actions);
    setLoading(true);
    const subscriptionData = {
      subscriptionId: data.subscriptionID,
    };

    try {
      await approveSubscriptionData(subscriptionData);
      setTimeout(() => {
        setLoading(false);
        navigate("/subs/thank-you");
      }, 2000);
    } catch (error) {
      setLoading(false);
      toast.error(
        "Something when wrong, please try again or contact our support."
      );
    }
  };
  const planFrag = useMemo(() => {
    return (
      <div className="flex flex-col justify-center gap-4 px-3 py-3 md:min-h-[30rem] md:flex-row md:gap-0 md:py-0">
        {plans.map((plan, index) => (
          <PlanCard
            key={index}
            onInit={onInit}
            plan={plan}
            userDetails={userDetails}
            handleCreate={handleCreate}
            handleApprove={handleApprove}
            isRetrying={isRetrying}
            currentPlanId={currentPlanId()}
            onCancel={onCancel}
          />
        ))}
      </div>
    );
  }, [userDetails, isRetrying, currentPlanId, plans, handleApprove]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="flex w-full min-h-24 my-5 justify-center items-center">
        <h1 className="text-3xl font-bold text-gray-700">Upgrade your plan</h1>
      </div>
      {planFrag}
      {loading && <LoadingOverlay />}
    </div>
  );
};

export default Subscription;
