import { useState } from "react";
import { toast } from "react-toastify";
import { forgotPassword } from "../../store/auth-action";
import { useNavigate } from "react-router-dom";

const ForgotPwdPopup = ({ togglePopup }) => {
  const [email, setEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const navigate = useNavigate();

  const onChangeEmail = (value) => {
    setEmail(value);
  };

  const sendEmailVerify = (e) => {
    e.preventDefault();

    const callAPI = async () => {
      setLoading(true);
      try {
        await forgotPassword(email);
        setTimeout(() => {
          setLoading(false);
          setSent(true);

          toast.success("Send Email Successfully");
          navigate("/reset-password?email=" + email);
        }, 2000);
      } catch (error) {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      }
    };
    callAPI();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="bg-black opacity-70 absolute inset-0"
        onClick={togglePopup}
      ></div>
      <div className="bg-white p-6 rounded shadow-lg z-10 md:w-[600px] h-60">
        <h2 className="text-xl font-bold mb-4">Forgot password?</h2>
        <hr></hr>
        <div className="py-7">
          {sent ? (
            <p className="mb-4 text-blue-500">
              Please check your email to continue to reset your password.
            </p>
          ) : (
            <p className="mb-4">Please enter your email address:</p>
          )}

          <div className="mt-2 flex">
            <input
              id="newEmail"
              name="newEmail"
              type="email"
              onChange={(e) => onChangeEmail(e.target.value)}
              className="block w-full rounded-[6px_0px_0px_6px] border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            />
            <button
              disabled={loading}
              onClick={sendEmailVerify}
              type="button"
              className="disabled:opacity-25 rounded-[0px_6px_6px_0px] bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPwdPopup;
