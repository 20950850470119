import axios from "axios";

export const getSubscriptions = async (query) => {
  try {
    const url = axios.getUri({ url: "/api/admin/subs", params: query });

    const response = await axios(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Failed to save subscription data:", error);
    return false;
  }
};

export const getSubscriptionDetail = async (subscriptionId) => {
  try {
    const url = `/api/admin/subs/${subscriptionId}`;
    const response = await axios(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Failed to save subscription data:", error);
    return false;
  }
};

export const getUserSubscription = async () => {
  try {
    const url = `/api/subscriptions`;
    const response = await axios(url, {
      method: "GET",
      credentials: "include",
    });

    return response.data;
  } catch (error) {
    console.error("Failed to save subscription data:", error);
    return false;
  }
};

export const saveSubscriptionData = async (subscriptionData) => {
  try {
    const url = `/api/subscriptions`;
    const response = await axios(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      data: JSON.stringify(subscriptionData),
    });

    return response.data;
  } catch (error) {
    console.error("Failed to save subscription data:", error);
    return false;
  }
};

export const approveSubscriptionData = async (subscriptionData) => {
  try {
    const url = `/api/subscriptions/approve`;
    const response = await axios(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      data: JSON.stringify(subscriptionData),
    });

    return response.data;
  } catch (error) {
    console.error("Failed to save subscription data:", error);
    return false;
  }
};

export const getPlans = async (query) => {
  try {
    const url = axios.getUri({ url: "/api/plan", params: query });

    const response = await axios(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Failed to save subscription data:", error);
    return false;
  }
};

export const adminGetPlans = async (subscriptionData) => {
  try {
    const url = `/api/admin/plans`;
    const response = await axios(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(subscriptionData),
    });

    return response.data;
  } catch (error) {
    console.error("Failed to save subscription data:", error);
    return false;
  }
};

export const adminUpdatePlan = async (data) => {
  const url = `/api/admin/plans/${data._id}`;
  return axios(url, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  });
};

export const adminUpdatePlanStatus = async (id, status) => {
  const url = `/api/admin/plans/${id}/status`;
  return axios(url, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ status }),
  });
};

export const adminCreatePlan = async (data) => {
  const url = `/api/admin/plans`;
  return axios(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  });
};

export const buyMoreSearch = async (data) => {
  const url = `/api/buyMore`;
  return axios(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  });
};

export const captureBuyMore = async (data) => {
  const url = `/api/capture`;
  return axios(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  });
};

export const adminGetTransaction = async (query) => {
  const url = axios.getUri({ url: "/api/admin/transaction", params: query });

  return axios(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
