/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useMemo } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import PaypalBuyButton from "./PayButton";

const PlanCard = ({
  plan,
  userDetails,
  handleApprove,
  onInit,
  isRetrying,
  handleCreate,
  onCancel,
}) => {
  const addBuyMore = plan.usageType === "BUYMORE";
  if (
    !addBuyMore &&
    userDetails.isSubscribed &&
    userDetails.subscription?.planId !== plan.planId
  ) {
    return <></>;
  }

  const renderCard = useMemo(() => {
    if (
      (userDetails.isSubscribed &&
        userDetails.subscription?.planId === plan.planId) ||
      plan.usageType === "TRIAL"
    ) {
      return (
        <div className="w-full flex justify-center">
          <button
            className="bg-gray-200 text-gray-500 py-2 p-4 rounded mb-4 text-center"
            disabled
          >
            Your current plan
          </button>
        </div>
      );
    }
    if (addBuyMore) {
      return (
        <PayPalScriptProvider
          options={{
            "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
          }}
        >
          <PaypalBuyButton showSpinner={false} />
        </PayPalScriptProvider>
      );
    }
    return (
      <PayPalScriptProvider
        options={{
          "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
          vault: "true",
        }}
      >
        {addBuyMore ? (
          <PaypalBuyButton showSpinner={false} />
        ) : (
          <PayPalButtons
            createSubscription={(data, actions) =>
              handleCreate(data, actions, plan.planId)
            }
            onInit={onInit}
            onCancel={onCancel}
            onApprove={handleApprove}
            style={{
              layout: "horizontal",
              color: "blue",
              shape: "pill",
              label: "subscribe",
            }}
          />
        )}

        {isRetrying && <div>Retrying to save subscription data...</div>}
      </PayPalScriptProvider>
    );
  }, [
    userDetails,
    handleApprove,
    plan.usageType,
    plan.planId,
    isRetrying,
    addBuyMore,
    onInit,
  ]);

  return (
    <div className="bg-white mx-2 rounded-lg shadow-lg max-w-sm bg-gradient-to-b from-transparent via-[#d8eaf4]">
      <div className="text-sm relative gap-5 border border-slate-100 md:min-h-[30rem] md:w-80 justify-center px-6 pt-6 pb-10 md:pb-6">
        <div className="w-full">
          <h2 className="text-gray-900 text-2xl text-center font-bold mb-2">
            {addBuyMore ? "Buy More" : plan.name}
          </h2>
        </div>
        <div className="text-black mb-2 p-4 flex-row items-baseline flex justify-center">
          <div className="font-[800] text-3xl">
            $
            {addBuyMore
              ? userDetails?.config?.buyMore?.amount
              : plan.pricingScheme.price}
          </div>
          <div className="font-semibold text-gray-500">
            /
            {addBuyMore
              ? "all time"
              : plan.frequency.intervalUnit.toLowerCase()}
          </div>
        </div>
        <div className="p-4">{renderCard}</div>
        <div
          className="flex p-4 items-center [&_p]:py-1 [&_li]:py-1"
          dangerouslySetInnerHTML={{
            __html: `${
              addBuyMore
                ? `<ul><li>✔️ Buy more ${userDetails?.config?.buyMore?.requestCount} search count with one click</li><li></ul>`
                : plan.description
            }`,
          }}
        />
      </div>
    </div>
  );
};

export default PlanCard;
