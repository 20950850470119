/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getMe } from "../../store/auth-action";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import LoadingOverlay from "../Ui/LoadingOverlay";
// import { refreshToken } from "../../store/auth-action";

export function AuthMiddleware({ component, ...rest }) {
  const isLogin = useSelector((state) => state.auth.isLogin);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(getMe());
  }, [location.pathname]);

  // useEffect(() => {
  //   const refreshTokenHandler = setInterval(() => {
  //     const isLoginLocal = localStorage.getItem("isLogin");
  //     if (isLoginLocal) {
  //       dispatch(refreshToken());
  //     }
  //   }, 25 * 60 * 1000);

  //   return () => clearInterval(refreshTokenHandler);
  // }, [dispatch]);

  if (!isLogin) {
    // Return a loading spinner or just null to avoid rendering App
    return <LoadingOverlay />; // Replace with your loader component if desired
  }

  return component;
}
