import { commonIcon } from "../../asset";
import { useSelector, useDispatch } from "react-redux";
import { chatAction } from "../../store/chat";
import { CHAT_TYPE } from "../../constants/index";
import { toast } from "react-toastify";

import { updateProfile } from "../../store/user-action";

const ChatType = () => {
  const dispatch = useDispatch();

  const isAdvanceGeminiPrompt = useSelector((state) => state.ui.isAdvanceShow);
  const chatType = useSelector((state) => state.chat.chatType);

  const setChatType = async (type) => {
    try {
      dispatch(chatAction.setChatType(type));
      await updateProfile({ type: type });
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  if (!isAdvanceGeminiPrompt) {
    return <></>;
  }

  return (
    <div className="left-4 top-16 absolute z-[6] w-[300px] p-2 rounded-lg shadow flex flex-col items-center gap-2 bg-gray-100">
      <p className="capitalize font-semibold self-start text-gray-500">
        Select Type
      </p>

      <label className="group inline-flex justify-between w-full items-center z-10 rounded-lg p-3 border border-transparent has-[:checked]:border-indigo-500 has-[:checked]:text-indigo-900 has-[:checked]:bg-indigo-50 has-[:checked]:font-bold hover:bg-slate-200 transition-all cursor-pointer has-[:checked]:transition-all has-[:checked]:duration-500 duration-500 relative [&_p]:has-[:checked]:translate-x-0 [&_p]:has-[:checked]:transition-transform [&_p]:has-[:checked]:duration-500 overflow-hidden">
        <div className="inline-flex items-center justify-center gap-2 relative z-10">
          <img
            src={commonIcon.chatGeminiIcon}
            className="size-6"
            alt="Gospel Truth"
          ></img>

          <p className="flex active items-center gap-1 font-semibold inset-0 w-full absolute left-8 -translate-y-[130px] group-has-[:checked]:opacity-100  group-has-[:checked]:translate-y-0  whitespace-nowrap transition-all duration-700 opacity-0">
            <span className="text-[#231F20]">Gospel</span>
            <span className="text-[#7B7C7F]">Truth</span>
            Private
          </p>
          <p className="flex items-center gap-1 font-semibold inset-0 w-full absolute left-8 translate-y-0 group-has-[:checked]:translate-y-[130%] whitespace-nowrap transition-all duration-700 opacity-40">
            <span className="text-[#231F20]">Gospel</span>
            <span className="text-[#7B7C7F]">Truth</span>
            Private
          </p>
        </div>
        <input
          className="checked:text-indigo-500 checked:ring-0 checked:ring-current focus:ring-0 focus:ring-current cursor-pointer"
          checked={chatType === CHAT_TYPE.PRIVATE}
          onChange={(e) => setChatType(CHAT_TYPE.PRIVATE)}
          name="chattype"
          type="radio"
        />
      </label>
      <label className="group inline-flex justify-between w-full items-center z-10 rounded-lg p-3 border border-transparent has-[:checked]:border-indigo-500 has-[:checked]:text-indigo-900 has-[:checked]:bg-indigo-50 has-[:checked]:font-bold hover:bg-slate-200 transition-all cursor-pointer has-[:checked]:transition-all has-[:checked]:duration-500 duration-500 relative [&_p]:has-[:checked]:translate-x-0 [&_p]:has-[:checked]:transition-transform [&_p]:has-[:checked]:duration-500 overflow-hidden">
        <div className="inline-flex items-center justify-center gap-2 relative z-10">
          <img
            src={commonIcon.chatGeminiIcon}
            className="size-6"
            alt="Gospel Truth"
          ></img>

          <p className="flex items-center gap-1 font-semibold inset-0 w-full absolute left-8 translate-y-0 group-has-[:checked]:opacity-100 whitespace-nowrap transition-all duration-700 opacity-40">
            <span className="text-[#231F20]">Gospel</span>
            <span className="text-[#7B7C7F]">Truth</span>
            Public
          </p>
        </div>
        <input
          className="checked:text-indigo-500 checked:ring-0 checked:ring-current focus:ring-0 focus:ring-current cursor-pointer"
          checked={chatType === CHAT_TYPE.PUBLIC}
          onChange={(e) => setChatType(CHAT_TYPE.PUBLIC)}
          name="chattype"
          type="radio"
        />
      </label>
    </div>
  );
};

export default ChatType;
