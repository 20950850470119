import axios from "axios";

export const adminGetUsers = async (query) => {
  try {
    const url =
      axios.getUri({ url: "/api/admin/users", params: query }) ||
      `/api/admin/users`;
    const response = await axios(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.data;
    return result;
  } catch (error) {
    console.error("Failed to save subscription data:", error);
    return false;
  }
};

export const adminDeactiveUsers = async (id) => {
  const url = `/api/admin/users/${id}/deactive`;
  return axios(url, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const adminDeleteUser = async (id) => {
  const url = `/api/admin/users/${id}`;
  return axios(url, {
    method: "DELETE",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const adminActiveUsers = async (id) => {
  const url = `/api/admin/users/${id}/active`;
  return axios(url, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const updateProfile = async (data) => {
  const url = `/api/user`;
  return axios(url, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  });
};

export const adminSentOTPChangeEmail = async (email) => {
  const url = `/api/admin/users/changeEmailOTP`;
  return axios(url, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ email }),
  });
};

export const adminVerifyChangeEmail = async (code) => {
  const url = `/api/admin/users/changeEmail`;
  return axios(url, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ code }),
  });
};

export const adminChangePassword = async (password, oldPassword) => {
  const url = `/api/admin/users/changePassword`;
  return axios(url, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ password, oldPassword }),
  });
};
