import { toast } from "react-toastify";
import { authAction } from "./auth";
import { userAction } from "./user";
import { chatAction } from "./chat";
import axios from "axios";

export const redirectHandler = async (code) => {
  const url = `/api/auth/google?code=${code}`;

  try {
    const response = await axios(url, {
      method: "GET",
      credentials: "include",
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw new Error("Invalid Credential");
  }
};

export const getMe = () => {
  return (dispatch) => {
    const url = `/api/auth/me`;

    axios(url, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 401) {
          dispatch(authAction.isLoginHandler({ isLogin: false }));
          localStorage.removeItem("isLogin");
          localStorage.removeItem("loginCheck");
          window.location.href = "/login";
          return;
        }
        return response.data;
      })
      .then((data) => {
        dispatch(
          userAction.setUserData({
            userData: {
              id: data._id,
              name: data.name,
              email: data.email,
              profileImg: data.profileImg,
              phone: data.phone,
              chatType: data.chatType,
              biography: data.biography,
              subscription: data.subscription,
              config: data.config,
              bought: data.bought,
              buyMore: data.buyMore,
              planLimit: data.planLimit,
              isSubscribed: data.isSubscribed,
              maxRateLimit: data.maxRateLimit,
              currentLimit: data.currentLimit,
            },
          })
        );
        dispatch(chatAction.setChatType(data.chatType));
        dispatch(authAction.isLoginHandler({ isLogin: true }));
        localStorage.setItem("isLogin", true);
      })
      .catch((err) => {
        console.log(err);
        dispatch(authAction.isLoginHandler({ isLogin: false }));
        localStorage.removeItem("isLogin");
        localStorage.removeItem("loginCheck");
      });
  };
};

export const logoutHandler = () => {
  return (dispatch) => {
    const url = `/api/auth/logout`;

    axios(url, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        localStorage.removeItem("isLogin");
        localStorage.removeItem("loginCheck");

        dispatch({
          type: "LOGOUT",
        });

        window.location.href = "/login";
      })
      .catch((err) => {
        console.log(err);
        localStorage.removeItem("isLogin");
        localStorage.removeItem("loginCheck");
        dispatch({
          type: "LOGOUT",
        });
        window.location.href = "/login";
      });
  };
};

export const refreshToken = () => {
  return (dispatch) => {
    const url = `/api/auth/resetToken`;

    axios(url, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        localStorage.setItem("isLogin", true);
      })
      .catch((err) => {
        console.log(err);
        dispatch(logoutHandler());
      });
  };
};

export const signupHandler = (useInput) => {
  return (dispatch) => {
    const url = `/api/auth/signup`;

    axios(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "X-Api-Key": apiKey,
      },
      // credentials: "include",
      data: JSON.stringify(useInput),
    })
      .then((response) => {
        window.location.href =
          " /ask-verification?email=" + response.data.email;
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
};

export const verifyEmail = async (email, code) => {
  const url = `/api/auth/email-verification`;
  try {
    const response = await axios(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        email,
        code,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const loginEmailHandler = (useInput) => {
  return (dispatch) => {
    const url = `/api/auth/login`;

    axios(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "X-Api-Key": apiKey,
      },

      // credentials: "include",
      data: JSON.stringify(useInput),
    })
      .then((response) => {
        dispatch(getMe());
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };
};

export const resendVerifyEmail = async (email) => {
  const url = `/api/auth/resend-email-verification`;
  try {
    const response = await axios(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        email,
      },
    });
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error;
  }
};

export const forgotPassword = async (email) => {
  const url = `/api/auth/forgot`;
  return axios(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      email,
    },
  });
};

export const resetPassword = async ({ email, code, password }) => {
  const url = `/api/auth/resetPwd`;
  return axios(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      email,
      code,
      password,
    },
  });
};
