import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { buyMoreSearch, captureBuyMore } from "../../store/subscription-action";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// Custom component to wrap the PayPalButtons and show loading spinner
const ButtonWrapper = ({ showSpinner }) => {
  // This value is from the props in the UI
  const style = { layout: "vertical" };
  const userDetails = useSelector((state) => state.user.user);

  const navigate = useNavigate();
  async function createOrder(data, actions) {
    // replace this url with your server
    const amount = userDetails?.config?.buyMore?.amount;
    if (!amount) return;
    try {
      const result = await actions.order.create({
        intent: "CAPTURE",
        purchase_units: [
          {
            amount: {
              value: amount,
            },
          },
        ],
      });
      await buyMoreSearch({ orderId: result });

      return result;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      throw error;
    }
  }
  function onApprove(data, actions) {
    // replace this url with your server

    return actions.order.capture().then(async (details) => {
      if (!details || !details.id) return;
      try {
        await captureBuyMore({ orderId: details.id, resource: details });
        toast.success("Transaction completed successfully");
        setTimeout(() => {
          return navigate("/home");
        }, 2000);
      } catch (error) {
        toast.error(error?.response?.data?.message);
        throw error;
      }
    });
  }

  const [{ isPending }] = usePayPalScriptReducer();

  return (
    <>
      {showSpinner && isPending && <div className="spinner" />}
      <PayPalButtons
        style={style}
        disabled={false}
        forceReRender={[style]}
        fundingSource={undefined}
        createOrder={(data, actions) => createOrder(data, actions)}
        onApprove={(data, actions) => onApprove(data, actions)}
      />
    </>
  );
};

export default ButtonWrapper;
